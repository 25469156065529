var variables = require(process.env.themeFolder);

require([
    "jquery",
],
function($) {
    $(document).ready(function(){
        if ($(window).width() <= variables.hiddenMobileMenu) {
            $('.has-submenu > a').on('click', function(event) {
                  event.preventDefault();
                  event.stopPropagation();
                  $(this).parent().siblings().removeClass('open');
                  $(this).parent().toggleClass('open');
            });
        }
    });
});
