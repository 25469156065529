var variables = require(process.env.themeFolder);

// missing forEach on NodeList for IE11
if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = Array.prototype.forEach;
}
Object.keys(variables).forEach(function(key) {
    var value = parseInt(variables[key].toString().replace(/^\s+|\s+$/g,'').replace('px', ''));
    variables[key] = (isNaN(value)) ? variables[key] : value;
});

requirejs([
    'jquery',
],
function($) {
    if ($('body').hasClass('catalog-product-view')) {
        $('.sticky-product-bar').on( 'click', 'button', function(e) {
            $('#product-addtocart-button').trigger(e);
        });

        $('.sticky-product-bar .towishlist').on( 'click', function(e) {
            e.preventDefault();
            $('[data-action=add-to-wishlist]').click();
        });

        if ($('#product-addtocart-button').length && $('body').hasClass('catalog-product-view')) {
            $(function() {
                $(window).scroll(function() {
                    var scroll = $(window).scrollTop();
                    var cartBtnOffset = $('#product-addtocart-button').offset().top -50;
                    var cartBtnHeight = $('#product-addtocart-button').height();

                    if ($(window).width() < [variables.sm]) {
                        var cartBtnOffset = $('#product-addtocart-button').offset().top -62;
                    }

                    $('.sticky-product-bar').toggleClass('show', (scroll > cartBtnOffset + cartBtnHeight));
                    $('body').toggleClass('hide', (scroll > cartBtnOffset + cartBtnHeight));
                });
            });
        }
    }
});
