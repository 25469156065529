require([
    "jquery",
],
function($) {
    $(document).ready(function() {
        $(document).on('scroll', function() {
            $('body').toggleClass('has-cookie-alert', $('.cookie').is(':visible'));
        });

        $('#btn-cookie-allow').on('click', function() {
            $('body').removeClass('has-cookie-alert');
        });

        /**
         * Close mobile menu when click outside menu
         */
        $(window).on('mousedown touchend', function () {
            if ($('#checkbox-toggle').is(':checked')) {
                closeMobileSubmenu($('.close-menu'));
            }
        });

        /**
         * Stop event propagation on navigation element
         */
        $('.navigation').on('mousedown touchend', function (event) {
            event.stopPropagation();
        });
    });

    /**
     * Close mobile submenu
     */
    function closeMobileSubmenu(self) {
        $(self).parents('.has-submenu').removeClass('open');
        $('#checkbox-toggle').prop('checked', false)
        closeMobileMenu();
    }

    /**
     * Close mobile menu
     */
    function closeMobileMenu() {
        $('body').toggleClass('mobile-menu-open', false);
    }
});
