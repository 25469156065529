// fixes preload problem on firefox but not yet in IE

// `rel=preload` Polyfill for <link> elements
var DOMTokenListSupports = function (tokenList, token) {
    if (!tokenList || !tokenList.supports) {
        return;
    }
    try {
        return tokenList.supports(token);
    }
    catch (e) {
        if (e instanceof TypeError) {
            console.log("The DOMTokenList doesn't have a supported tokens list");
        }
        else {
            console.error("That shouldn't have happened");
        }
    }
};
var linkSupportsPreload = DOMTokenListSupports(document.createElement('link').relList, 'preload');
if (!linkSupportsPreload) {
    // Dynamically load the things that relied on preload.
    var links = document.getElementsByTagName('link');
    for (var i = 0; i < links.length; i++) {
        var link = links[i];
        // qualify links to those with rel=preload and as=style attrs
        if (link.rel === 'preload' && link.getAttribute('as') === 'style') {
            // prevent re-running on link
            link.setAttribute('rel', 'stylesheet');
        }
    }
}
