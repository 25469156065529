requirejs([
    'jquery',
],
function($) {
    $(document).ready(() => {
        $('input[data-binds]').on('click', (e) => {
            $(e.target.getAttribute('data-binds')).trigger(e);
        });
    });
});
