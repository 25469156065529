require([
    "jquery",
],
function($) {
    $(document).ready(function(){

        var filterHeight;

        $('.catalog-topnav .product-filters__item').on('click', function(){
            $('.catalog-topnav').css('min-height', '');
            $('.catalog-topnav .filter-content').css('max-height', 0);
            $(this).siblings('.product-filters__item').each(function(){
                $(this).find('.filter-toggling').prop('checked', false);
            });

            if ($(this).find('> input').is(':checked')) {
                filterHeight = $(this).find('.filter-content form').height();
                $(this).find('.filter-content').css('max-height', filterHeight+82);
                $('.catalog-topnav').css('min-height', filterHeight+115);
            } else {
                $(this).find('.filter-content').css('max-height', 0);
            }
        });

        $('.page-wrapper').on('click touchend', '.toolbar-top .filter a', function(){
            $('.block.filter').addClass('open');
            $('body').addClass('filter-menu-open');
            return false;
        });

        $('.page-wrapper').on('click touchend', '.sidebar-main .filters-close', function(){
            $('.block.filter').removeClass('open');
            $('body').removeClass('filter-menu-open');
            return false;
        });

        $('.page-wrapper').on('blur', '.mobile-close-filters', function(){
            $('.block.filter').removeClass('open');
            $('body').removeClass('filter-menu-open');

            // scroll to productlisting
            $('html, body').animate({
                scrollTop: $("#amasty-shopby-product-list").offset().top
            }, 100);

            // super duper gay solution
            if (typeof checkExist === 'undefined') {
                var checkExist = setInterval(function() {
                    if ($('.sidebar-main > .mobile-close-filters').length) {
                        $('.sidebar-main > .mobile-close-filters').remove();
                        clearInterval(checkExist);
                    }
                }, 100);
            }
        });
    });
});
