var variables = require(process.env.themeFolder);

requirejs([
    'jquery',
    'swiper'
],
function($, Swiper) {
    $(document).ready(function () {
        $('.product-slider .product-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-button-next"></div>');
        $( '.products-list, .products-grid' ).addClass( 'swiper-transition' );

        //initialize swiper when document ready
        var mainSlider = new Swiper('.product-slider .products-list, .product-slider .products-grid', {
            containerModifierClass: 'products-grid, product-items',
            wrapperClass: 'product-items',
            slideClass: 'product-item',
            loop: variables.mainSlideLoop,
            loopedSlides: 10,
            slidesPerView: variables.mainSlidesXs,
            slidesPerColumn: 1,
            spaceBetween: variables.mainSlidesSpaceBetweenSmall,
            observer: true,
            observeParents: true,
            breakpointsInverse: true,

            breakpoints: {
                [variables.xs]: {
                    slidesPerView: variables.mainSlidesXs,
                    spaceBetween: variables.mainSlidesSpaceBetweenSmall,
                },

                [variables.sm]: {
                    slidesPerView: variables.mainSlidesSm,
                    paceBetween: variables.mainSlidesSpaceBetween,
                },

                [variables.md]: {
                    slidesPerView: variables.mainSlidesMd,
                    paceBetween: variables.mainSlidesSpaceBetween,
                },

                [variables.lg]: {
                    slidesPerView: variables.mainSlidesLg,
                    paceBetween: variables.mainSlidesSpaceBetween,
                },

                [variables.xl]: {
                    slidesPerView: variables.mainSlidesXl,
                    paceBetween: variables.mainSlidesSpaceBetween,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.product-slider .swiper-button-next',
                prevEl: '.product-slider .swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination'
            }
        });

        $('.upsell .product-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-button-next"></div>');
        $('.products-grid').addClass('swiper-transition');

        var upsellSlider = new Swiper('.upsell .products-grid', {
            containerModifierClass: 'products-grid',
            wrapperClass: 'product-items',
            slideClass: 'product-item',
            loop: variables.upsellLoop,
            slidesPerView: variables.upsellSlidesXs,
            slidesPerColumn: 1,
            spaceBetween: variables.upsellSlidesSpaceBetween,
            observer: true,
            observeParents: true,
            breakpointsInverse: true,

            breakpoints: {
                [variables.xs]: {
                    slidesPerView: variables.upsellSlidesXs,
                },

                [variables.sm]: {
                    slidesPerView: variables.upsellSlidesSm,
                },

                [variables.md]: {
                    slidesPerView: variables.upsellSlidesMd,
                },

                [variables.lg]: {
                    slidesPerView: variables.upsellSlidesLg,
                },

                [variables.xl]: {
                    slidesPerView: variables.upsellSlidesXl,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.upsell .swiper-button-next, .crosssell .swiper-button-next',
                prevEl: '.upsell .swiper-button-prev, .crosssell .swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination'
            }
        });

        $('.crosssell .product-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-button-next"></div>');
        $('.products-grid').addClass('swiper-transition');

        var crosssellSlider = new Swiper('.crosssell .products-grid', {
            containerModifierClass: 'products-grid',
            wrapperClass: 'product-items',
            slideClass: 'product-item',
            loop: variables.crossellLoop,
            slidesPerView: variables.crossellSlidesXs,
            slidesPerColumn: 1,
            spaceBetween: variables.crossellSlidesSpaceBetween,
            observer: true,
            observeParents: true,
            breakpointsInverse: true,

            breakpoints: {
                [variables.xs]: {
                    slidesPerView: variables.crossellSlidesXs,
                },

                [variables.sm]: {
                    slidesPerView: variables.crossellSlidesSm,
                },

                [variables.md]: {
                    slidesPerView: variables.crossellSlidesMd,
                },

                [variables.lg]: {
                    slidesPerView: variables.crossellSlidesLg,
                },

                [variables.xl]: {
                    slidesPerView: variables.crossellSlidesXl,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.upsell .swiper-button-next, .crosssell .swiper-button-next',
                prevEl: '.upsell .swiper-button-prev, .crosssell .swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination'
            }
        });

        $('.related .product-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-button-next"></div>');
        $( '.products-grid' ).addClass( 'swiper-transition' );

        var relatedProductsSlider = new Swiper('.related .products-grid', {
            containerModifierClass: 'products-grid',
            wrapperClass: 'product-items',
            slideClass: 'product-item',
            loop: variables.relatedLoop,
            slidesPerView: variables.relatedSlidesXs,
            slidesPerColumn: 1,
            spaceBetween: variables.relatedSlidesSpaceBetween,
            observer: true,
            observeParents: true,
            breakpointsInverse: true,

            breakpoints: {
                [variables.xs]: {
                    slidesPerView: variables.relatedSlidesXs,
                },

                [variables.sm]: {
                    slidesPerView: variables.relatedSlidesSm,
                },

                [variables.md]: {
                    slidesPerView: variables.relatedSlidesMd,
                },

                [variables.lg]: {
                    slidesPerView: variables.relatedSlidesLg,
                },

                [variables.xl]: {
                    slidesPerView: variables.relatedSlidesXl,
                }
            },

            // Navigation arrows
            navigation: {
                nextEl: '.related .swiper-button-next',
                prevEl: '.related .swiper-button-prev'
            },
            pagination: {
                el: '.swiper-pagination'
            }
        });

        window.sliders = [
            {wrapperClass: '.related .products-grid', swiper: relatedProductsSlider},
            {wrapperClass: '.crosssell .products-grid', swiper: crosssellSlider},
            {wrapperClass: '.upsell .products-grid', swiper: upsellSlider},
            {wrapperClass: '.product-slider .products-list, .product-slider .products-grid', swiper: mainSlider}
        ]

        Object.keys(window.sliders).forEach(function(key){
            lazyloadSwiperImages(sliders[key].swiper, sliders[key].wrapperClass);
        });
    });

    function lazyloadSwiperImages(swiperSlider, wrapperClass)
    {
        try {
            swiperSlider.on('slideChange', function (e) {
                var $img = $(wrapperClass)
                       .find('.product-item img, .product-item source');
   
               showImage($img);
           });
        } catch (e) {}
    }

    function showImage($img)
    {
        $img.each(function(){
            var src = $(this).data('original');
            if (src) {
                $(this).attr('src', src);
            }

            var srcset = $(this).data('originalset');
            if (srcset) {
                $(this).attr('srcset', srcset).addClass('loaded');
            }
        });

    }
});
