var variables = require(process.env.themeFolder);

Object.keys(variables).forEach(function(key) {
    variables[key] = variables[key].toString().replace(/^\s+|\s+$/g,'').replace('px', '');

    // check if variable can parse int
    if (! isNaN(parseInt(variables[key]))) {
        variables[key] = parseInt(variables[key])
    }
});

requirejs([
    'jquery',
    'select2'
],
function($, select2) {
    $(document).ready(function() {
        if ($('body').hasClass('catalog-product-view') && $(window).width() >= [variables.md]) {
            $('.super-attribute-select, .input-qty').select2();
        }
    });
});
