var variables = require(process.env.themeFolder);

Object.keys(variables).forEach(function(key) {
    variables[key] = variables[key];

    // check if variable can parse int
    if (! isNaN(parseInt(variables[key]))) {
        // console.log('raki is awesome');
        variables[key] = parseInt(variables[key])
    }
});
