exports = module.exports = require("../../../../../../../../vendor/justbetter/magento2mix/node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "", ""]);

// exports
exports.locals = {
	"gridGapColumn": "15px",
	"gridGapRow": "30px",
	"xs": "420px",
	"sm": "768px",
	"md": "992px",
	"lg": "1400px",
	"xl": "1600px",
	"hiddenMobileMenu": "1200px",
	"mainSlidesXs": "2",
	"mainSlidesSm": "3",
	"mainSlidesMd": "4",
	"mainSlidesLg": "4",
	"mainSlidesXl": "4",
	"mainSlidesSpaceBetweenSmall": "20px",
	"mainSlidesSpaceBetween": "20px",
	"mainSlideLoop": "0",
	"mainSlidesCatalogXs": "2",
	"mainSlidesCatalogSm": "3",
	"mainSlidesCatalogMd": "3",
	"mainSlidesCatalogLg": "3",
	"mainSlidesCatalogXl": "3",
	"upsellSlidesXs": "2",
	"upsellSlidesSm": "2",
	"upsellSlidesMd": "2",
	"upsellSlidesLg": "2",
	"upsellSlidesXl": "2",
	"upsellSlidesSpaceBetween": "20px",
	"upsellLoop": "0",
	"crossellSlidesXs": "2",
	"crossellSlidesSm": "3",
	"crossellSlidesMd": "4",
	"crossellSlidesLg": "4",
	"crossellSlidesXl": "4",
	"crossellSlidesSpaceBetween": "20px",
	"crossellLoop": "0",
	"relatedSlidesXs": "2",
	"relatedSlidesSm": "2",
	"relatedSlidesMd": "2",
	"relatedSlidesLg": "2",
	"relatedSlidesXl": "2",
	"relatedSlidesSpaceBetween": "20px",
	"relatedLoop": "0",
	"CategoryBlocksSlidesXs": "2",
	"CategoryBlocksSlidesSm": "3",
	"CategoryBlocksSlidesMd": "4",
	"CategoryBlocksSlidesLg": "4",
	"CategoryBlocksSlidesXl": "4",
	"CategoryBlocksSlidesSpaceBetween": "15px",
	"CategoryBlocksLoop": "0",
	"galleryThumbsUseControl": "false",
	"galleryThumbsNavButtons": "true",
	"galleryThumbsFreeMode": "true",
	"galleryThumbsCenteredSlides": "false",
	"galleryThumbsDirection": "horizontal",
	"galleryThumbsSpaceBetween": "10",
	"galleryThumbsSlidesPerView": "4",
	"galleryThumbsLoop": "0",
	"galleryTopLoop": "0",
	"brandSlidesXs": "1",
	"brandSlidesSm": "3",
	"brandSlidesMd": "4",
	"brandSlidesLg": "5",
	"brandSlidesXl": "5",
	"brandSlidesSpaceBetween": "10px",
	"brandSlideLoop": "0"
};