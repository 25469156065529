requirejs([
    'jquery',
    'select2'
], function($) {
    if ($('body').hasClass('account')) {
        $.each($('select'), function(index, value){
            $(this).select2();
        });
    }
});
