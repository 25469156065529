require('es6-promise').polyfill();
var axios = require('axios');

require('fg-loadcss');
require('./criticalfix');
require('./variables');
require('./pop');
require('./popup');
require('./mobile-submenu');
require('./select2');
require('./account');
require('./swiper');
require('./cross-browser-fix');
require('./gallery');
require('./header');
require('./back-to-top');
require('./filter-menu');
