requirejs([
    'jquery',
],
function($) {
    $(document).ready(() => {
        hideContainers();
    });

    /**
     * Hides related product containers if has no products
     */
    function hideContainers() {
        $.each([
            'product-related-container',
            'product-upsell-container',
            'accessories-container',
            'othersviewed-container'
        ], function(index, container) {
            if (!$('.' + container).find('.products').length > 0) {
                $('.' + container).hide();
            }
        })
    }
});
