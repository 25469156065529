requirejs([
    'jquery',
    'select2'
],
function($, select2) {
    $(document).ready(function(){
        $('.product-filters select, .product-options-wrapper select').each(function(){
            $(this).select2();
        });

        var qtySelector = $('.control.qty select').select2();

        qtySelector.on('select2:select', function(e){
            $('.action.update').click();
        });
    });
});
