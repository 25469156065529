requirejs([
    'jquery',
    'select2'
],
function($, select2) {
    $(document).ready(function() {
        var qtySelector = $('.control.qty select').select2();

        qtySelector.on('select2:select', function(e) {
            $('.action.update').click();
        });

        /**
         * Navigate to previous page if clicked on 'Continue Shopping' button in cart
         */
        $('.cart-top-actions .button.continue').on('click touchend', (event) => {
            event.stopPropagation();
            window.history.back();

            return false;
        })
    });
});
