require([
    "jquery",
],
function($) {
    $(document).ready(function(){
        var isIE = !!navigator.userAgent.match(/Trident/g) || !!navigator.userAgent.match(/MSIE/g);

        if (isIE) {
            $('body').addClass('old-grid-spec');
        }
    });
});
