require([
    "jquery"
],
function($) {
    $(document).ready(function(){
        $(document).on('click', '.modal-popup', function(e){
            let $target = $(e.target);

            if ($target.hasClass('modal-popup'))
            {
                $target.find('.action-close').click();
            }
        });
    });
});
