var variables = require(process.env.themeFolder);

requirejs([
    'jquery',
    'swiper'
],

function($, Swiper) {
    $(document).ready(function() {
        if ( $('.page-header .header-usps').length ) {
            const breakpoint = window.matchMedia( '(min-width:992px)' );
            let headerUspsSwiper;

            const breakpointChecker = function() {
                if ( breakpoint.matches === true ) {
                    if ( headerUspsSwiper !== undefined ) {
                        headerUspsSwiper.destroy( true, true );
                        $('.page-header .header-usps ul').unwrap('.swiper-container-usps');
                        $('.page-header .header-usps ul').removeClass('swiper-wrapper');
                        $('.page-header .header-usps li').removeClass('swiper-slide');
                        $('.swiper-button-prev, .swiper-button-next').remove();
                    }
                    return;
                } else if ( breakpoint.matches === false ) {
                    return enableSwiper();
                }
            };

            const enableSwiper = function() {
                $('.page-header .header-usps ul').wrap('<div class="swiper-container-usps"></div>')
                $('.page-header .header-usps ul').addClass('swiper-wrapper');
                $('.page-header .header-usps ul li').addClass('swiper-slide');

                var slidesPerColumn = 1,
                slidesPerColumnResponsive = 1;

                headerUspsSwiper = new Swiper('.swiper-container-usps', {
                    loop: true,
                    allowTouchMove: false,
                    autoplay: {
                        delay: 3500,
                        disableOnInteraction: false,
                    },
                    slidesPerView: 1,
                    spaceBetween: 0,
                });
            };

            breakpoint.addListener(breakpointChecker);
            breakpointChecker();
        }

        if ($('.banner-slider ul li').length > 1) {
            const enableSwiper = function() {
                var bannerSlider = new Swiper('.swiper-container', {
                    loop: true,
                    slidesPerColumn: 1,
                    slidesPerView: 1,
                    spaceBetween: 0,
                    observer: true,
                    observeParents: false,
                    breakpointsInverse: true,
                    autoplay: {
                        delay: 3000,
                        disableOnInteraction: false,
                    },

                    // Navigation arrows
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true,
                    },
                });
            };

            return enableSwiper();
        }

        if ($('.banner-slider ul li').length == 1) {
            $('.swiper-pagination').addClass('disable');
        }

        const enableAccessoriesSlider = function() {
            var variables = require(process.env.themeFolder);
            $('.accessories-container .product-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-button-next"></div>');
            $('.products-grid').addClass('swiper-transition');

            var accessoriesSlider = new Swiper('.accessories-container .products-grid', {
                containerModifierClass: 'products-grid',
                wrapperClass: 'product-items',
                slideClass: 'product-item',
                loop: variables.relatedLoop,
                slidesPerView: variables.relatedSlidesXs,
                slidesPerColumn: 1,
                spaceBetween: variables.relatedSlidesSpaceBetween,
                observer: true,
                observeParents: true,
                breakpointsInverse: true,

                breakpoints: {
                    [variables.xs]: {
                        slidesPerView: variables.relatedSlidesXs,
                    },

                    [variables.sm]: {
                        slidesPerView: variables.relatedSlidesSm,
                    },

                    [variables.md]: {
                        slidesPerView: variables.relatedSlidesMd,
                    },

                    [variables.lg]: {
                        slidesPerView: variables.relatedSlidesLg,
                    },

                    [variables.xl]: {
                        slidesPerView: variables.relatedSlidesXl,
                    }
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.accessories-container .products-grid .swiper-button-next',
                    prevEl: '.accessories-container .products-grid .swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            });
        }

        const enableOthersviewedSlider = function() {
            var variables = require(process.env.themeFolder);
            $('.othersviewed-container .product-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-button-next"></div>');
            $('.products-grid').addClass('swiper-transition');

            var othersviewedSlider = new Swiper('.othersviewed-container .products-grid', {
                containerModifierClass: 'products-grid',
                wrapperClass: 'product-items',
                slideClass: 'product-item',
                loop: variables.relatedLoop,
                slidesPerView: variables.relatedSlidesXs,
                slidesPerColumn: 1,
                spaceBetween: variables.relatedSlidesSpaceBetween,
                observer: true,
                observeParents: true,
                breakpointsInverse: true,

                breakpoints: {
                    [variables.xs]: {
                        slidesPerView: variables.relatedSlidesXs,
                    },

                    [variables.sm]: {
                        slidesPerView: variables.relatedSlidesSm,
                    },

                    [variables.md]: {
                        slidesPerView: variables.relatedSlidesMd,
                    },

                    [variables.lg]: {
                        slidesPerView: variables.relatedSlidesLg,
                    },

                    [variables.xl]: {
                        slidesPerView: variables.relatedSlidesXl,
                    }
                },

                // Navigation arrows
                navigation: {
                    nextEl: '.othersviewed-container .products-grid .swiper-button-next',
                    prevEl: '.othersviewed-container .products-grid .swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination'
                }
            });
        }
        const enableCategoryBlocksSlider = function() {
            var variables = require(process.env.themeFolder);
            $('.category-grid .category-items').before('<div class="swiper-button-prev"></div>').after('<div class="swiper-pagination"></div><div class="swiper-button-next"></div>');
            $('.category-grid').addClass('swiper-transition');
            var categoryBlocksSlider = new Swiper('.category-grid', {
                wrapperClass: 'category-items',
                slideClass: 'category-item',
                loop: variables.CategoryBlocksLoop,
                slidesPerView: variables.CategoryBlocksSlidesXs,
                slidesPerColumn: 1,
                spaceBetween: variables.CategoryBlocksSlidesSpaceBetween,
                observer: true,
                observeParents: true,
                breakpointsInverse: true,

                // Navigation arrows
                navigation: {
                    nextEl: '.category-grid .swiper-button-next',
                    prevEl: '.category-grid .swiper-button-prev'
                },
                pagination: false,
                breakpoints: {
                    [variables.xs]: {
                        slidesPerView: variables.CategoryBlocksSlidesXs,
                    },

                    [variables.sm]: {
                        slidesPerView: variables.CategoryBlocksSlidesSm,
                    },

                    [variables.md]: {
                        slidesPerView: variables.CategoryBlocksSlidesMd,
                    },

                    [variables.lg]: {
                        slidesPerView: variables.CategoryBlocksSlidesLg,
                    },

                    [variables.xl]: {
                        slidesPerView: variables.CategoryBlocksSlidesXl,
                    }
                }
            });
        }



        enableOthersviewedSlider();
        enableAccessoriesSlider();
        enableCategoryBlocksSlider();
        return;
    });
});
