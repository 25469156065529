requirejs([
    'jquery',
], function($) {
    $('#amasty_shopby_apply_filters').on('click', function () {
        if ($('body').hasClass('filter-menu-open')) {
            $('body').removeClass('filter-menu-open');
        }
    });
    
    $(document).on('amscroll_refresh', () => $('body').toggleClass('filter-menu-open', $('.block.filter').hasClass('open')));
});
