var variables = require(process.env.themeFolder);

require([
    "jquery",
],
function($) {
    var headerHeight =  $('.page-header').height(),
    scrollTop;

    // Prevent transitions on pageload
    // After pageload remove body class
    $(window).on('load', function() {
        $("body").removeClass("preload");
    });

    $(document).ready(function() {
        menu();

        function menu() {
            if ($(window).width() <= variables.md) {
                $('label[for="algoliasearchchecked"]').on('click', function(){
                    if ($('#algoliasearchchecked').is(':checked')) {
                        $('#algolia-autocomplete-listbox-0').hide();
                    }
                });
            }
        }

        /**
         * Close mobile menu when click outside menu
         */
        $(window).click(function () {
            if ($('#checkbox-toggle').is(':checked')) {
                closeMobileSubmenu($('.close-menu'));
            }
        });

       /**
         * Stop event propagation on navigation element
         */
        $('.navigation').click(function (event) {
            event.stopPropagation();
        });

        /**
         * Toggle checkbox to open and close mobile menu
         */
        $('label[for="checkbox-toggle"]').on('click touchend', function(){
            $('body').toggleClass('mobile-menu-open', !$('#checkbox-toggle').is(':checked'));
        });

        /**
         * Close mobile menu click event
         */
        $('label.close').on('click', function(){
            closeMobileMenu();
        });

        /**
         * Close mobile menu from submenu item click event
         */
        $('.close-menu').on('click', function(){
            closeMobileSubmenu(this);
        });

        /**
         * Navigate a step back from submenu item
         */
        $('.step-back').on('click', function(){
            $(this).parents('.has-submenu').first().removeClass('open');
        });

        $('.algolia-search-input').on('keyup', function(){
            if($(this).val() == '') {
                $('#algolia-glass').show();
                $('.clear-query-autocomplete').hide();
            } else {
                $('.clear-query-autocomplete').show();
                $('#algolia-glass').hide();
            }
        });

        /**
         * Scroll event
         */
        $(document).on('scroll', function(){
            var scrollTop = $(this).scrollTop();
            toggleStickyHeaderClass(scrollTop);
        });
    });

    /**
     * Add or remove sticky class to body element
     */
    function toggleStickyHeaderClass(thisScrollTop) {
        if (thisScrollTop >= headerHeight && ! $('body').hasClass('sticky')) {
            $('body').removeClass('not-sticky').addClass('sticky');
        } else if (thisScrollTop < headerHeight ){
            $('body').removeClass('sticky').addClass('not-sticky');
        }
        headerHeight = $('.page-header').height();
    }

    /**
     * Close mobile menu
     */
    function closeMobileMenu() {
        $('body').toggleClass('mobile-menu-open', false);
    }

    /**
     * Close mobile submenu
     */
    function closeMobileSubmenu(self) {
        $(self).parents('.has-submenu').removeClass('open');
        $('#checkbox-toggle').prop('checked', false)
        closeMobileMenu();
    }
});
